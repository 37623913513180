<template>
  <div class="limiter">
    <div class="container-login100">
      <div class="wrap-login100">
        <div class="login100-pic js-tilt" data-tilt>
          <img src="@/assets/images/illustrator-login.svg" alt="IMG" />
        </div>

        <form
          class="login100-form validate-form"
          method="post"
          @submit.prevent="loginSubmit"
        >
          <div class="container-logo">
            <img src="@/assets/images/logo.jpg" alt="LOGO" class="logo" />
          </div>

          <template v-if="!forgotPasswordStatus">
            <div class="row">
              <CustomInput
                className="col-12"
                id="userNameOrPhoneOrEmail"
                :errors="errors_user"
                :value="userLogin.user"
                v-on:changeValue="
                  userLogin.user = $event;
                  $v.userLogin.user.$touch();
                "
                :title="$t('userNameOrPhoneOrEmail')"
                :imgName="'man.svg'"
              />
              <CustomInputPassword
                className="col-12"
                id="userPassword"
                :errors="errors_userPassword"
                :value="userLogin.userPassword"
                v-on:changeValue="
                  userLogin.userPassword = $event;
                  $v.userLogin.userPassword.$touch();
                "
                :title="$t('password')"
                :imgName="'passwordlock.svg'"
              />
            </div>
            <div class="container-login100-form-btn">
              <button name="login" type="submit" class="login100-form-btn">
                {{ $t("login") }}
              </button>
            </div>

            <div class="rememberMe-forgotPassword">
              <button
                class="forgot-password-btn"
                @click.prevent="
                  forgotPasswordStatus = true;
                  userLogin.setInitialValue();
                "
              >
                {{ $t("forgotPassword") }}
              </button>
            </div>
          </template>

          <template v-if="forgotPasswordStatus">
            <template v-if="!codeSentStatus">
              <div class="row">
                <b-form-group
                  v-model="selected"
                  :options="options"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio
                    v-model="selected"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="phone"
                    >{{ $t("recieveCodeOnPhone") }}</b-form-radio
                  >
                  <b-form-radio
                    v-model="selected"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="email"
                    >{{ $t("recieveCodeOnEmail") }}</b-form-radio
                  >
                </b-form-group>
              </div>
              <div class="row">
                <CustomInput
                  v-show="selected === 'phone'"
                  className="col-12"
                  id="phoneOrEmail"
                  :errors="errors_phoneOrEmail"
                  :value="userLogin.phoneOrEmail"
                  v-on:changeValue="
                    userLogin.phoneOrEmail = $event;
                    $v.userLogin.phoneOrEmail.$touch();
                  "
                  :title="$t('UserValidSettings.UserPhone')"
                  :imgName="'phone.svg'"
                />
                <CustomInput
                  v-show="selected === 'email'"
                  className="col-12"
                  id="phoneOrEmail"
                  :errors="errors_phoneOrEmail"
                  :value="userLogin.phoneOrEmail"
                  v-on:changeValue="
                    userLogin.phoneOrEmail = $event;
                    $v.userLogin.phoneOrEmail.$touch();
                  "
                  :title="$t('UserValidSettings.UserEmail')"
                  :imgName="'email.svg'"
                />
              </div>
              <div id="send-code" class="container-login100-form-btn">
                <button
                  class="login100-form-btn"
                  @click.prevent="sendConfirmCode"
                >
                  {{ $t("sendConfirmCode") }}
                </button>
              </div>
              <div class="rememberMe-forgotPassword">
                <button
                  class="forgot-password-btn"
                  @click.prevent="resetAllForgetPassword"
                >
                  {{ $t("login") }}
                </button>
              </div>
              <div
                id="recaptcha-container"
                style="background-color: #1b1a1a; width: 300px; margin: auto"
              ></div>
            </template>

            <template v-if="codeSentStatus">
              <CustomInput
                className="col-12"
                id="otpNum"
                :errors="errors_otpNum"
                :value="userLogin.otpNum"
                v-on:changeValue="
                  userLogin.otpNum = $event;
                  $v.userLogin.otpNum.$touch();
                "
                :title="$t('verificationCode')"
                :imgName="'code.svg'"
              />
              <div id="send-code" class="container-login100-form-btn">
                <button class="login100-form-btn" @click.prevent="confirmCode">
                  {{ $t("confirm") }}
                </button>
              </div>

              <div class="rememberMe-forgotPassword">
                <button
                  class="forgot-password-btn"
                  @click.prevent="resetAllForgetPassword"
                >
                  {{ $t("login") }}
                </button>
              </div>
            </template>
          </template>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/general/CustomInput.vue";
import CustomInputPassword from "@/components/general/CustomInputPassword.vue";
import { isValidPassword } from "@/utils/validationHelper";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import firebase from "firebase";
import { objectToFormData, getLanguage } from "@/utils/functions";
import { STATUS, ESTABLISHMENT_TOKEN } from "@/utils/constants";
import generalMixin from "@/utils/generalMixin";

export default {
  mixins: [validationMixin, generalMixin],
  components: {
    CustomInput,
    CustomInputPassword,
  },
  props: ["userLogin"],
  data() {
    return {
      selected: "phone",
      options: [
        { text: "Using phone", value: "phone" },
        { text: "Using email", value: "email" },
      ],
      forgotPasswordStatus: false,
      confirmationResult: null,
      recaptchaVerifier: null,
      recaptchaWidgetId: null,
      confirmResult: null,
      codeSentStatus: false,
      codeSentTo: null,
      userToken: null,
      userPhone: null,
      userEmail: null,
    };
  },
  mounted() {},
  async created() {
    this.resetAllForgetPassword();
  },
  validations: {
    userLogin: {
      user: { required },
      phoneOrEmail: { required },
      otpNum: { required },
      userPassword: { isValidPassword },
    },
  },
  computed: {
    errors_user() {
      let errors = [];
      if (this.$v.userLogin.user.$error) {
        if (!this.$v.userLogin.user.required)
          errors.push(this.$t("validation.fieldIsRequired"));
      }
      return errors;
    },
    errors_phoneOrEmail() {
      let errors = [];
      if (this.$v.userLogin.phoneOrEmail.$error) {
        if (!this.$v.userLogin.phoneOrEmail.required)
          errors.push(this.$t("validation.fieldIsRequired"));
      }
      return errors;
    },
    errors_otpNum() {
      let errors = [];
      if (this.$v.userLogin.otpNum.$error) {
        if (!this.$v.userLogin.otpNum.required)
          errors.push(this.$t("validation.fieldIsRequired"));
      }
      return errors;
    },
    errors_userPassword() {
      let errors = [];
      if (this.$v.userLogin.userPassword.$error) {
        if (!this.$v.userLogin.userPassword.isValidPassword)
          errors.push(this.$t("validation.passwordIsNotValid"));
      }
      return errors;
    },
  },
  methods: {
    async sendConfirmCode() {
      this.$v.$touch();
      if (this.$v.userLogin.phoneOrEmail.required) {
        // check user exist
        this.$emit("isLoading", true);
        let model = {
          language: getLanguage(),
          establishmentToken: ESTABLISHMENT_TOKEN,
          user: this.userLogin.phoneOrEmail,
        };
        let formData = objectToFormData(model);

        try {
          const response = await this.userLogin.checkUser(formData);
          if (response.data.status == STATUS.SUCCESS) {
            this.userToken = response.data.apiAppData.userData.userToken;
            this.userPhone = response.data.apiAppData.userData.userPhoneWithCC;
            this.userEmail = response.data.apiAppData.userData.userEmail;
            if (this.selected === "phone") {
              // user  type phone and send code on phone using firebase
              await this.setRecaptcha();
              this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                "recaptcha-container"
              );
              this.recaptchaVerifier.render().then((widgetId) => {
                this.recaptchaWidgetId = widgetId;
              });

              firebase
                .auth()
                .signInWithPhoneNumber(this.userPhone, this.recaptchaVerifier)
                .then((confirmationResult) => {
                  this.confirmResult = confirmationResult;
                  this.codeSentStatus = true;
                  this.codeSentTo = "phone";
                })
                .catch((error) => {
                  this.showMsg(error.message);
                });
            } else {
              // user  type email and receive code on email
              this.codeSentStatus = true;
              this.codeSentTo = "email";
            }
          } else {
            this.showMsg(response.data.msg);
          }
        } catch (e) {
          this.showMsg(this.$t("errorCatch"));
        }
        this.$emit("isLoading", false);
      }
    },
    async confirmCode() {
      this.$v.$touch();
      if (this.$v.userLogin.otpNum.required) {
        switch (this.codeSentTo) {
          case "phone":
            this.verifyCodeOnPhone();
            break;
          case "email":
            this.verifyCodeOnEmail();
            break;
          default:
            break;
        }
      }
    },
    verifyCodeOnPhone() {
      this.confirmResult
        .confirm(this.userLogin.otpNum)
        .then(() => {
          this.resetAllForgetPassword();
          this.$emit("changePassword", this.userToken);
        })
        .catch((error) => {
          this.showMsg(error);
        });
    },
    async verifyCodeOnEmail() {
      this.$emit("isLoading", true);
      let model = {
        language: getLanguage(),
        establishmentToken: ESTABLISHMENT_TOKEN,
        userEmail: this.userEmail,
        forgetCode: this.userLogin.otpNum,
      };
      let formData = objectToFormData(model);

      try {
        const response = await this.userLogin.verifyEmailCode(formData);
        if (response.data.status == STATUS.SUCCESS) {
          this.resetAllForgetPassword();
          this.$emit("changePassword", this.userToken);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.$emit("isLoading", false);
    },
    resetAllForgetPassword() {
      this.userLogin.setInitialValue();
      this.forgotPasswordStatus = false;
      this.codeSentStatus = false;
      this.codeSentTo = null;
    },

    async loginSubmit() {
      // bottomSheetScrollToTop();
      this.$v.$touch();
      if (
        this.$v.userLogin.user.required &&
        this.$v.userLogin.userPassword.isValidPassword
      ) {
        this.$emit("login");
      }
    },
    async setRecaptcha() {
      firebase.auth().useDeviceLanguage();
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "send-code",
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        }
      );
    },
  },
};
</script>

<style lang="scss">
// .v-application--is-rtl {
//   .custom-radio {
//     &.custom-control-label::after,
//     &.custom-control-label::before {
//       left: auto !important;
//       right: -1.5rem !important;
//     }
//   }
// }
// .v-application--is-ltr {
//   .custom-radio {
//     &.custom-control-label::after,
//     &.custom-control-label::before {
//       left: -1.5rem !important;
//       right: auto !important;
//     }
//   }
// }
</style>
>
